import "./header.css";
export default function Header() {
  return (
    <div className="header">
      <div className="headerContent">
        <h2>Özgün bir tasarım herşeyi değiştirebilir</h2>
        <div className="line"></div>
        <h1>Harika bir duygu</h1>
        <a href="#" className="ctn">
          Devamı...
        </a>
      </div>
    </div>
  );
}
