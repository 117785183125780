import Header from "./header/Header";
import Navbar from "./navbar/Navbar";


function App() {
  return (
    <>
      <Navbar/>
      <Header/>
    </>
  );
}

export default App;
