import { FaBars, FaGitter } from "react-icons/fa";
import "./navbar.css";

export default function Navbar() {
  return (
    <div className="navbar">
      <span className="logo">`Desing 360</span>
      <ul className="navbarLinks ">
        <li className="active">Anasayfa</li>
        <li>Projeler</li>
        <li>Blog</li>
        <li>Hakkımızda</li>
        <li className="ctn">İletişim</li>
      </ul>
      <span
        className="menuBtn"
        onClick={() => {
          document.querySelector(".navbarLinks").classList.toggle("mobilMenu");
        }}
      >
        <FaGitter />
      </span>
    </div>
  );
}
